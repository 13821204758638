import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../../classes/base/second-page-edit-base';
import { ProvincesService } from '../../../dapfood/services/provinces.service';
import { DistrictsService } from '../../../dapfood/services/districts.service';
import { CustomerTypesService } from '../../../dapfood/services/customerTypes.service';
import { WardsService } from '../../../dapfood/services/wards.service';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../../shared/utilities';
import { CustomerTypeOption, UserType } from '../../../dapfood/common/constant';
import { UsersTempService } from '../../../dapfood/services/userstemp.service';
export class UserInformationTdvEditComponent extends SecondPageEditBase {
    constructor(_injector, _ProvincesService, _DistrictsService, _customerTypesService, _WardsService, _UserService, _UsersTempService, _configurationService) {
        super(null, _injector);
        this._injector = _injector;
        this._ProvincesService = _ProvincesService;
        this._DistrictsService = _DistrictsService;
        this._customerTypesService = _customerTypesService;
        this._WardsService = _WardsService;
        this._UserService = _UserService;
        this._UsersTempService = _UsersTempService;
        this._configurationService = _configurationService;
        this.isShow = false;
        this.customerTypes_options = [];
        this.district_options = [];
        this.userGroups_options = [];
        this.pharmacyType_options = [];
        this.tdvGroups_options = [];
        this.ward_options = [];
        this.district_options_temp = [];
        this.ward_options_temp = [];
        this.placeOfIssueDkdn_options = [];
        this.province_options = [];
        this.isLoading = false;
        this.submitted = false;
        this.isCheckCompared = false;
        this.modelEdit = {
            userId: 0,
            name: "",
            idType: -1
        };
        this.originalModelEdit = {
            userId: 0,
            name: "",
            idType: -1
        };
        this.isFormConfirmEdit = false;
        this.modelEditByTdv = {};
        this.dataDifference = {};
        this.isCreate = false;
        this.UserType = UserType;
        this.accountType_options = [];
        this.isShowAccountType = false;
        this.isFormTdv = false;
        this.titleHeader = "Thêm/Sửa nhà thuốc";
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.init();
        });
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.vi = this._configurationService.calendarVietnamese;
            this.formGroup = new FormGroup({
                name: new FormControl('', Validators.compose([Validators.required])),
                phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
                password: new FormControl(''),
                email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
                code: new FormControl(''),
                avatar: new FormControl(''),
                legalRepresentatives: new FormControl(''),
                pharmacistPtcm: new FormControl(''),
                idCardPtcm: new FormControl(''),
                phonePtcm: new FormControl(''),
                idProvince: new FormControl(''),
                idDistrict: new FormControl(''),
                idWard: new FormControl(''),
                address: new FormControl(''),
                certificateNumberDkdn: new FormControl(''),
                certificateDkdnDate: new FormControl(''),
                placeOfIssueDkdn: new FormControl(''),
                certificateDkdn: new FormControl(''),
                certificateNumberDdkkd: new FormControl(''),
                certificateDdkkdDate: new FormControl(''),
                placeOfIssueDdkkd: new FormControl(''),
                certificateDdkkd: new FormControl(''),
                certificateNumberGdpGppDsp: new FormControl(''),
                certificateGdpGppDspDate: new FormControl(''),
                placeOfIssueGdpGppDsp: new FormControl(''),
                certificateGdpGppDsp: new FormControl(''),
                idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
                userCustomerTypes: new FormControl(''),
                userName: new FormControl(''),
                passwordRepeat: new FormControl(''),
                idCustomerType: new FormControl(''),
                invoiceCompanyName: new FormControl(''),
                invoiceEmail: new FormControl(''),
                invoiceTaxCode: new FormControl(''),
                invoiceAddress: new FormControl(''),
                idGroup: new FormControl(''),
                idType: new FormControl(''),
                lat: new FormControl(''),
                lng: new FormControl(''),
            });
            this.formGroupTdv = new FormGroup({
                name: new FormControl('', Validators.compose([Validators.required])),
                phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
                password: new FormControl(''),
                email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
                code: new FormControl(''),
                avatar: new FormControl(''),
                legalRepresentatives: new FormControl(''),
                pharmacistPtcm: new FormControl(''),
                idCardPtcm: new FormControl(''),
                phonePtcm: new FormControl(''),
                idProvince: new FormControl(''),
                idDistrict: new FormControl(''),
                idWard: new FormControl(''),
                address: new FormControl(''),
                certificateNumberDkdn: new FormControl(''),
                certificateDkdnDate: new FormControl(''),
                placeOfIssueDkdn: new FormControl(''),
                certificateDkdn: new FormControl(''),
                certificateNumberDdkkd: new FormControl(''),
                certificateDdkkdDate: new FormControl(''),
                placeOfIssueDdkkd: new FormControl(''),
                certificateDdkkd: new FormControl(''),
                certificateNumberGdpGppDsp: new FormControl(''),
                certificateGdpGppDspDate: new FormControl(''),
                placeOfIssueGdpGppDsp: new FormControl(''),
                certificateGdpGppDsp: new FormControl(''),
                idPharmacyType: new FormControl('', Validators.compose([Validators.required])),
                userCustomerTypes: new FormControl(''),
                userName: new FormControl(''),
                passwordRepeat: new FormControl(''),
                idCustomerType: new FormControl(''),
                idGroup: new FormControl(''),
                invoiceCompanyName: new FormControl(''),
                invoiceEmail: new FormControl(''),
                invoiceTaxCode: new FormControl(''),
                invoiceAddress: new FormControl(''),
                idType: new FormControl(''),
                lat: new FormControl(''),
                lng: new FormControl(''),
            });
            yield this.loadUserTypes(); // Loại khách hàng
            yield this.loadPharmacyTypes();
            this.accountType_options = [
                { label: 'Loại tài khoản', value: -1 },
                { label: 'Nhà thuốc', value: UserType.NhaThuoc },
                { label: 'Trình dược viên', value: UserType.TrinhDuocVien },
                { label: 'Doanh nghiệp', value: UserType.DoanhNghiep },
                { label: 'Kho', value: UserType.Kho },
            ];
        });
    }
    showPopup(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isFormConfirmEdit = data.isFormConfirmEdit;
            this.modelEdit = {};
            this.originalModelEdit = {};
            this.isCheckCompared = false;
            this.modelEditByTdv = {};
            this.isShow = true;
            this.submitted = false;
            this.modelEdit.idType = data.idType;
            // Reset lại các checkbox khi mở popup
            this.resetAllCheckboxes();
            this.formGroup.reset();
            this.formGroupTdv.reset();
            this.isShowAccountType = data.isShowAccountType;
            this.dataDifference = {};
            yield this.onLoadProvinces();
            if (data.id > 0) {
                this.formGroup.get('password').clearValidators();
                this.formGroup.get('password').updateValueAndValidity();
                // await this._UserService.GetByUserId(data.id).then(async rs => {
                //   if (rs.status) {
                //     this.modelEdit = rs.data;
                //     if (this.modelEdit.certificateDkdnDate) {
                //       this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
                //     }
                //     if (this.modelEdit.certificateGdpGppDspDate) {
                //       this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
                //     }
                //     if (this.modelEdit.certificateDdkkdDate) {
                //       this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
                //     }
                //   }
                // }, () => {
                //   this._notifierService.showHttpUnknowError();
                // });
                //  await this._UserService.GetUserTempById(data.id).then(async rs => {
                //   if (rs.status) {
                //     this.modelEditByTdv = rs.data;
                //     if (this.modelEditByTdv.certificateDkdnDate) {
                //       this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
                //     }
                //     if (this.modelEditByTdv.certificateGdpGppDspDate) {
                //       this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
                //     }
                //     if (this.modelEditByTdv.certificateDdkkdDate) {
                //       this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
                //     }
                //   }
                // }
                // , () => {
                //   this._notifierService.showHttpUnknowError();
                // });
                yield this._UsersTempService.CompareTwoTableUserAndUserTemp(data.id).then((rs) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (rs.status) {
                        this.modelEdit = rs.data.user;
                        this.originalModelEdit = Object.assign({}, this.modelEdit);
                        this.modelEditByTdv = rs.data.userTemp;
                        this.dataDifference = rs.data.differences;
                        this.modelEditByTdv.avatar = rs.data.userTemp.avatar;
                        if (this.modelEdit.certificateDkdnDate) {
                            this.modelEdit.certificateDkdnDate = new Date(this.modelEdit.certificateDkdnDate);
                        }
                        if (this.modelEdit.certificateGdpGppDspDate) {
                            this.modelEdit.certificateGdpGppDspDate = new Date(this.modelEdit.certificateGdpGppDspDate);
                        }
                        if (this.modelEdit.certificateDdkkdDate) {
                            this.modelEdit.certificateDdkkdDate = new Date(this.modelEdit.certificateDdkkdDate);
                        }
                        if (this.modelEditByTdv.certificateDkdnDate) {
                            this.modelEditByTdv.certificateDkdnDate = new Date(this.modelEditByTdv.certificateDkdnDate);
                        }
                        if (this.modelEditByTdv.certificateGdpGppDspDate) {
                            this.modelEditByTdv.certificateGdpGppDspDate = new Date(this.modelEditByTdv.certificateGdpGppDspDate);
                        }
                        if (this.modelEditByTdv.certificateDdkkdDate) {
                            this.modelEditByTdv.certificateDdkkdDate = new Date(this.modelEditByTdv.certificateDdkkdDate);
                        }
                    }
                }), () => {
                    this._notifierService.showHttpUnknowError();
                });
                // if (data.tdvEditTemp != null && data.tdvEditTemp > 0) {
                //   this.formGroup.addControl('isEditTdvTemp ', new FormControl(data.tdvEditTemp));
                //   this.modelEdit.isEditTdvTemp = data.tdvEditTemp
                // }
            }
            else {
                this.formGroup.get('password').setValidators([Validators.required, Validators.minLength(6)]);
                this.formGroup.get('password').updateValueAndValidity();
                this.modelEdit = {
                    userId: 0,
                    idType: data.idType
                };
                this.isCreate = true;
            }
            if (this.modelEdit.idType === UserType.NhaThuoc) {
                this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
            }
            else {
                this.formGroup.get('idPharmacyType').clearValidators();
            }
            this.formGroup.get('idPharmacyType').updateValueAndValidity();
            if (data.idType === -1 && data.id <= 0) {
                this.formGroup.get('accountType').setValidators([Validators.required]);
                this.formGroup.get('accountType').updateValueAndValidity();
            }
            if (this.modelEdit.idType === UserType.NhaThuoc)
                yield this.loadUserGroupsNT();
            this.onLoadDistricts();
            this.onLoadWards();
            this.onLoadDistrictsTemp();
            this.onLoadWardsTemp();
        });
    }
    // load bản cập nhật do tdv update
    //loại khách hàng
    loadUserTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.customerTypes_options = [];
            yield this._customerTypesService.GetShort('', CustomerTypeOption.IdCustomerType, 0, 10000).then(rs => {
                if (rs.status) {
                    let dataArr = [];
                    rs.data.forEach(item => {
                        dataArr.push({ label: item.name, value: item.id });
                    });
                    this.customerTypes_options = dataArr;
                }
            });
        });
    }
    //loại hình nhà thuốc
    loadPharmacyTypes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.pharmacyType_options = [];
            yield this._customerTypesService.GetShort('', CustomerTypeOption.idPharmacyType, 0, 10000).then(rs => {
                if (rs.status) {
                    rs.data.forEach(item => {
                        this.pharmacyType_options.push({ label: item.name, value: item.id });
                    });
                }
            });
        });
    }
    onLoadDistricts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._DistrictsService.GetShort(this.modelEdit.idProvince).then(rs => {
                if (rs.status) {
                    this.district_options = rs.data;
                }
            });
        });
    }
    onLoadProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._ProvincesService.GetShort().then(rs => {
                if (rs.status) {
                    this.province_options = rs.data;
                    this.placeOfIssueDkdn_options = rs.data;
                }
            });
        });
    }
    onLoadWards() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._WardsService.GetShort(this.modelEdit.idDistrict).then(rs => {
                if (rs.status) {
                    this.ward_options = rs.data;
                }
            });
        });
    }
    onLoadDistrictsTemp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._DistrictsService.GetShort(this.modelEditByTdv.idProvince).then(rs => {
                if (rs.status) {
                    this.district_options_temp = rs.data;
                }
            });
        });
    }
    onLoadWardsTemp() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._WardsService.GetShort(this.modelEditByTdv.idDistrict).then(rs => {
                if (rs.status) {
                    this.ward_options_temp = rs.data;
                }
            });
        });
    }
    //nhóm khách hàng
    loadUserGroupsNT() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._customerTypesService.GetShort('', CustomerTypeOption.IdGroupNT, 0, 10000).then(rs => {
                if (rs.status) {
                    var list = [];
                    rs.data.forEach(item => {
                        list.push({ label: item.name, value: item.id });
                    });
                    this.userGroups_options = list;
                }
            });
        });
    }
    save() {
        this.isLoading = true;
        this.submitted = true;
        if (this.formGroup.valid) {
            this.modelEdit.username = this.modelEdit.phone;
            this.modelEdit.passwordRepeat = this.modelEdit.password;
            let _userCustomerTypes = [];
            this.modelEdit.userCustomerTypes = _userCustomerTypes;
            if (this.isFormConfirmEdit) {
                this.formGroup.addControl('confirmUpdate', new FormControl('', Validators.required));
                this.modelEdit.confirmUpdate = true;
            }
            this._UserService.post(this.modelEdit).then((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.status) {
                    if (response.data != null && response.data.userId > 0) {
                        this.modelEdit.userId = response.data.userId;
                        this.modelEdit.isDeleted = 0;
                        this._notifierService.showSuccess(response.message);
                        this.closePopupMethod(response.data);
                    }
                }
                else {
                    this.isLoading = false;
                    this._notifierService.showWarning('Cập nhật thông tin người dùng thất bại.\n' + response.message);
                }
            }))
                .catch(error => {
                this.isLoading = false;
                this._notifierService.showWarning(Utilities.getErrorDescription(error));
            });
        }
        else {
            const invalid = this.findInvalidControls();
            this._notifierService.showError(invalid, 'Vui lòng nhập đủ thông tin');
        }
        this.isLoading = false;
    }
    findInvalidControls() {
        const invalidFields = [];
        const controls = this.formGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                const controlTitle = this.getControlTitle(name) || 'invalid'; // Kiểm tra nếu không có tiêu đề thì gán giá trị 'invalid'
                const ce = controls[name].errors;
                if (ce.required) {
                    if (controlTitle === 'invalid') {
                        invalidFields.push('Vui lòng F5 lại trang.');
                    }
                    else {
                        invalidFields.push(`Vui lòng nhập ${controlTitle}`);
                    }
                }
                if (ce.minlength) {
                    if (controlTitle === 'invalid') {
                        invalidFields.push('Vui lòng làm mới lại trang.');
                    }
                    else {
                        invalidFields.push(`${controlTitle} ít nhất ${ce.minlength.requiredLength} ký tự`);
                    }
                }
                if (ce.email) {
                    if (controlTitle === 'invalid') {
                        invalidFields.push('Vui lòng làm mới lại trang.');
                    }
                    else {
                        invalidFields.push(`${controlTitle} sai định dạng`);
                    }
                }
            }
        }
        return invalidFields.length ? invalidFields.join(', ') : '';
    }
    closePopupMethod(data) {
        this.formGroup.reset();
        this.formGroupTdv.reset();
        this.resetAllCheckboxes();
        this.isCheckCompared = false;
        this.isShow = false;
        this.closePopup.next(data);
    }
    getControlTitle(name) {
        switch (name) {
            case 'name':
                return 'Tên nhà thuốc/phòng khám/TDV';
            case 'phone':
                return 'Số điện thoại';
            case 'password':
                return 'Mật khẩu';
            case 'email':
                return 'Email';
            case 'idPharmacyType':
                return 'Loại tài khoản';
        }
    }
    accountTypeChange() {
        if (this.modelEdit.idType === UserType.NhaThuoc) {
            this.formGroup.get('idPharmacyType').setValidators([Validators.required]);
            this.loadUserGroupsNT();
        }
        else {
            this.formGroup.get('idPharmacyType').clearValidators();
        }
        this.formGroup.get('idPharmacyType').updateValueAndValidity();
    }
    onCheckboxChange(event) {
        var checkbox = event.target;
        var controlName = checkbox.getAttribute('data-control-name');
        if (controlName) {
            if (checkbox.checked) {
                if (controlName === 'location') {
                    this.modelEdit.idProvince = this.modelEditByTdv.idProvince;
                    this.modelEdit.idDistrict = this.modelEditByTdv.idDistrict;
                    this.modelEdit.idWard = this.modelEditByTdv.idWard;
                    this.onLoadDistricts();
                    this.onLoadWards();
                }
                else if (controlName === 'locateLatLng') {
                    this.modelEdit.lat = this.modelEditByTdv.lat;
                    this.modelEdit.lng = this.modelEditByTdv.lng;
                }
                else {
                    this.modelEdit[controlName] = this.modelEditByTdv[controlName];
                }
            }
            else {
                if (this.originalModelEdit) {
                    if (controlName === 'location') {
                        this.modelEdit.idProvince = this.originalModelEdit.idProvince;
                        this.modelEdit.idDistrict = this.originalModelEdit.idDistrict;
                        this.modelEdit.idWard = this.originalModelEdit.idWard;
                        this.onLoadDistricts();
                        this.onLoadWards();
                    }
                    else if (controlName === 'locateLatLng') {
                        this.modelEdit.lat = this.originalModelEdit.lat;
                        this.modelEdit.lng = this.originalModelEdit.lng;
                    }
                    else {
                        this.modelEdit[controlName] = this.originalModelEdit[controlName];
                    }
                }
            }
        }
    }
    resetAllCheckboxes() {
        const checkboxes = document.querySelectorAll('input[type="checkbox"][data-control-name]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        console.log(checkboxes);
    }
    onSelectCompare(event) {
        console.log(event);
        this.isCheckCompared = event;
        if (this.isCheckCompared) {
            const differentFields = Object.keys(this.dataDifference)
                .filter(key => this.dataDifference[key] === false);
            differentFields.forEach(field => {
                this.modelEdit[field] = this.modelEditByTdv[field];
                if (field === 'lat' || field === 'lng') {
                    const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="locateLatLng"]`);
                    if (checkbox) {
                        checkbox.checked = true;
                    }
                }
                else if (field === 'idProvince' || field === 'idDistrict' || field === 'idWard') {
                    const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="location"]`);
                    if (checkbox) {
                        checkbox.checked = true;
                        this.onLoadDistricts();
                        this.onLoadWards();
                    }
                }
                else {
                    const checkbox = document.querySelector(`input[type="checkbox"][data-control-name="${field}"]`);
                    if (checkbox) {
                        checkbox.checked = true;
                    }
                }
            });
        }
    }
    viewLocation(lat, lng) {
        if (lat && lng) {
            const url = `https://www.google.com/maps?q=${lat},${lng}`;
            window.open(url, '_blank');
        }
        else {
            alert('Toạ độ không hợp lệ!!!.');
        }
    }
}
